<template>
  <div>
    <Header :headerIndex="11" @showSearch="showSearch1"></Header>
    <div class="container">
      <!-- <img class="g_top_banner" src="../assets/static/icon/icon_top_banner6.png" alt="" /> -->
      <iframe v-show="isShowSearch" class="iframe" src="https://s.hcut.cn/search?___lang=en-us" frameborder="0"></iframe>

      <div v-show="!isShowSearch" class="max_1440 distributors_wrap">
        <!-- <div style="color:white;" v-html="precut.content"></div> -->
        <img style="display: block; width: 100%" src="../assets/static/images/img7.jpg" />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";
import api from "../api";
export default {
  components: {},
  data() {
    return {
      precut: [],
      isShowSearch:false
    };
  },
  created() {
    this.getprecut();
  },
  methods: {
    getprecut() {
      api.doPost("/api/index/getprecut", {}, (res) => {
        // console.log(res)
        if (res.code == 200) {
          this.precut = res.data;
        }
      });
    },
    showSearch1(){
      this.isShowSearch = !this.isShowSearch
    }
  },
};
</script>

<style scoped>
.iframe{
  width: 100%;
  height: calc(100vh - 80px);
}

.distributors_wrap {
  padding: 60px 0;
}

@media screen and (max-width: 1440px) {
  .distributors_wrap {
    padding: 45px 0;
  }
}

@media screen and (max-width: 768px) {
  .distributors_wrap {
    padding: 24px 0;
  }
}
</style>
